<template>
  <div class="px-4 font-Noto-Sans user-list-page">
    <v-sheet color="transparent">
      <v-row>
        <v-col cols="12" class="pl-0 pr-0">
          <v-container class="mobile-view">
            <v-img
              :src="require('@/assets/images/logo-small.png')"
              max-height="37"
              height="37"
              max-width="37"
              width="37"
              class="rounded-circle m-auto"
              lazy
            ></v-img>
            <p class="page-head">コトナル 利用規約</p>
            <div class="page-content">
              <p class="mb-3">
                本基本規約は、株式会社ＦＮＣ（以下、「当社」といいます）が提供する外国人求職者向け求人情報掲載サービス「Ｍｕｓｔ」やその他求人サービスおよびそれらに関連するアプリケーションをご利用になるすべての方に共通して適用されます。
              </p>
              <p class="mb-0">第１条（定義）</p>
              <p class="mb-0">
                １．本サービスは、当社が「Ｍｕｓｔ」の名称にて、本サイト（以下で定義します）又はアプリケーションにおいてで運営する外国人求職者向け求人情報掲載サービスその他関連するサービスの総称をいいます（その名称又はサービス内容が変更された場合、変更後のサービスを含むものとします）。
              </p>
              <p class="mb-0">
                ２．本サービスは、労働基準法等にて規定する就労年齢に達した方を対象にし、また、出入国管理及び難民認定法その他日本国内において就労することが認められている方を対象にしたものです。
              </p>
              <p class="mb-0">
                ３．本基本規約その他当社が定めるすべての規約にご同意いただくことによって、本サービスをご利用いただくことができます。
              </p>
              <p class="mb-0">
                ４．利用者とは、本サービスを利用する方をいいます。
              </p>
              <p class="mb-0">
                ５．IDとは、当社が提供するサービスまたはアプリケーションのご利用、お申し込みに関する本人確認手段、認証手段として利用することができる共通IDプログラムのことをいいます。
              </p>
              <p class="mb-0">
                ６．当社の特定のサービスについては、本基本規約のほかに各サービスサイト固有の個別利用規約が設けられている場合があります。本基本規約と個別利用規約（以下、「本規約等」といいます）が異なっている場合には、特段の定めがない限り、当該サービスに関してはそのサービスサイト固有の個別利用規約が本基本規約に優先して適用されるものとします。
              </p>
              <p class="mb-3">
                ７．本サービスは原則日本国内における求職・求人を対象とします。
              </p>

              <p class="mb-0">第２条（IDの登録）</p>
              <p class="mb-0">
                １．利用者は、本基本規約にご同意の上、当社所定の手続に従い、IDのアカウント登録を行っていただく必要があります。その際に、顔写真、パスポート、在留カード（表裏両面）その他本人であることが確認でき、有効な在留資格を保有していることを確認できる書類を提出していただきます
              </p>
              <p class="mb-0">
                ２．利用者は、前項所定のアカウント登録申請をし、当社が承認したときに、IDに対応したサービスの利用ができます。
              </p>
              <p class="mb-0">
                ３．当社は、以下の場合、事前または事後にアカウント登録を承認しないことがあります。
              </p>
              <p class="mb-0">
                （１）就労に際して有効な在留資格を保有していない場合
              </p>
              <p class="mb-0">（２）本規約等に違反したことがある場合</p>
              <p class="mb-0">（３）第三者になりすました場合</p>
              <p class="mb-0">（４）登録情報に虚偽があることが判明した場合</p>
              <p class="mb-0">
                （５）すでにIDのアカウントとして登録されている情報と同一の情報で登録しようとした場合
              </p>
              <p class="mb-3">
                （６）前各号のほか、不適切なアカウント登録であると当社が判断した場合
              </p>

              <p class="mb-0">第３条（IDの管理）</p>
              <p class="mb-0">
                １．利用者は、IDまたはパスワードの使用・管理を自らの責任で行うものとし、第三者に行わせることはできません。
              </p>
              <p class="mb-0">
                ２．利用者は、自己のIDおよびパスワードの使用および管理について、一切の責任を負担するものとします。利用者のIDおよびパスワードの管理が不十分なため、第三者に使用されることによって損害が生じた場合はすべて利用者が負担するものとします。
              </p>
              <p class="mb-0">
                ３．利用者は、当社へ正確かつ最新の情報を提供、登録し、自らの責任で本サービスを利用するものとします。
              </p>
              <p class="mb-0">
                ４．利用者は、情報に変更があった場合、直ちに最新の情報に変更するものとします。
              </p>
              <p class="mb-0">
                ５．利用者は、理由の如何を問わず、IDおよびパスワードを第三者に使用させ、あるいは譲渡、貸与、名義変更、売買、担保設定等できません。
              </p>
              <p class="mb-3">
                ６．利用者は、ID、パスワード、提供情報または登録情報に起因して利用企業等の第三者との間で紛争等が生じた場合、自らの責任において、これを解決するものとします。
              </p>

              <p class="mb-0">第４条（料金）</p>
              <p class="mb-3">
                利用者は、本サービスを無料で利用できます（当社が有料と指定した場合を除きます。その場合においては、当社の定める料金を申込書に従って当社指定の銀行口座に振込にて支払う又はクレジットカードにて支払うものとします。）。
              </p>

              <p class="mb-0">第５条（利用者準備）</p>
              <p class="mb-3">
                利用者は、本サービスへアクセスするためのインターネットインフラ、ソフトウェア、その他の機器等を自らの責任と費用負担にて準備、設置または操作等をする必要があります。当社は、準備、設置または操作方法等について、一切関与しません。
              </p>

              <p class="mb-0">第６条（ログイン状態の保持）</p>
              <p class="mb-0">
                １．ログイン状態を保持するため、利用者が本サービスを利用いただく際にCookie情報を取得します。Cookie情報とは、Webサーバーによって利用者が使用するコンピューターのハードディスクへ保存されるテキストファイルとなります。特定の個人を識別する情報を含む内容ではありません。
              </p>
              <p class="mb-3">
                ２．利用者は、一度ログイン行うと、次回以降ログイン状態が保持されます。ログイン状態を解除したい場合はログアウトを行ってください。※ログイン状態が保持された状態で、第三者が同じ端末を使用した場合、データを閲覧される可能性があります。利用者本人専用の端末ではない場合、当該端末よりログアウトを行う、またはCookieの削除をする等のご対応をお願いいたします。
              </p>

              <p class="mb-0">第７条（サービスの利用制限）</p>
              <p class="mb-3">
                当社は、サービスのご利用をIDのアカウント登録者または会員に限定したり、一定の年齢以上の方や一定の在留資格を有する方に限定したり、当社が定める本人確認などの手続を経て一定の要件を満たした利用者のみに限定したりするなど、利用に際して条件を付すことができるものとします。
              </p>

              <p class="mb-0">第８条（利用者の送信内容）</p>
              <p class="mb-0">
                １．利用者は、本サービスの送信内容において、第三者の著作権・商標権等の知的財産権、名誉・信用・プライバシー・肖像権等の人格的権利または契約により設定された権利その他のいかなる権利も侵害することはできません。万一、当該送信内容中に第三者の権利等が含まれる場合、利用者は、自ら適法な手段により入手するものとします。
              </p>
              <p class="mb-0">
                ２．送信内容の著作権は、当社に帰属するものとします。
              </p>
              <p class="mb-3">
                ３．当社または本サービス利用企業は、送信内容を本サービスの目的の範囲内で利用します。
              </p>

              <p class="mb-0">第９条（メールマガジンの配信）</p>
              <p class="mb-0">
                １．当社は、希望する利用者に対してメールマガジンの配信を行います。
              </p>
              <p class="mb-3">
                ２．IDのアカウント登録者または会員は、対象のサイトのメニュー設定画面より、メールマガジンの配信または配信の停止を設定することができます（変更が反映されるまで通常１週間かかります）。
              </p>

              <p class="mb-0">第１０条（禁止行為）</p>
              <p class="mb-0">
                １．本サービスにおいて、以下の行為を禁止します。
              </p>
              <p class="mb-0">（１）当社または第三者を誹謗中傷する行為</p>
              <p class="mb-0">
                （２）当社または第三者の財産権、著作権等の知的財産権もしくはプライバシー等の法的権利を侵害する行為
              </p>
              <p class="mb-0">
                （３）本サービスを利用した営業活動または営利を目的とした情報収集・提供・使用等の行為
              </p>
              <p class="mb-0">（４）虚偽の情報を提供または登録する行為</p>
              <p class="mb-0">
                （５）いたずら応募等の就労する意思がないのに応募する行為
              </p>
              <p class="mb-0">（６）公序良俗に反する行為</p>
              <p class="mb-0">（７）本規約等または法令に反する一切の行為</p>
              <p class="mb-0">
                （８）その他本サービスの運営の妨げや当社の信頼を毀損する等当社が不適切と判断する一切の行為
              </p>
              <p class="mb-0">
                ２．当社は、前項に違反する利用者に対して、事前の通知をすることなく、以下の各号の措置を行うことがあります。
              </p>
              <p class="mb-0">
                （１）応募情報、提供情報または登録情報の閲覧の禁止もしくは削除
              </p>
              <p class="mb-0">（２）本サービスの一部または全部の利用禁止</p>
              <p class="mb-3">（３）IDの利用停止または会員からの除名</p>

              <p class="mb-0">第１１条（当社が保証しない事項）</p>
              <p class="mb-0">
                １．本サービスにおいて提供する求人情報または本サービス利用企業の情報は、第三者の責任において提供されるものであり、内容の正確性、適切性または有用性等について当社は保証しません。
              </p>
              <p class="mb-0">
                ２．当社が、本サービスを承認した場合であっても、利用者が本サービス利用企業において適法に就労できる在留資格を有することを保証するものではありません。利用者ご自身で，在留資格や就労可能範囲について調査してください。
              </p>
              <p class="mb-0">
                ３．本サービス利用企業の掲載内容が真実であること、業務内容や雇用条件等が法令に適合していること及び求人情報記載の通りの雇用条件を締結できることを保証するものではなく、利用者は自己の責任において、求人企業に労働条件等を直接確認した後に雇用契約を締結するものとします。また、利用者は、本サービスの利用に関し、本サービス利用企業又は第三者との間で紛争が発生した場合には、当事者間で解決するものとし、当社は責任を負いません。
              </p>
              <p class="mb-0">
                ４．当社は、本サービスの提供において、エラー、中断または障害が生じないことを保証するものではありません。
              </p>
              <p class="mb-0">
                ５．当社は、通常講ずるべき対策で防止できないウィルス・不正アクセス被害、停電被害、サーバー故障、回線障害または天変地変による被害その他の当社の責によらない不可抗力による被害が生じた場合、一切の責任・危険を負わないものとします。
              </p>
              <p class="mb-0">
                ６．当社は、障害、不可抗力またはサイト閉鎖等により、本サービスのデータが消去・変更されないことを保証するものではありません。本サービスにおいて保存が必要なデータがある場合、利用者は、自己の責任において保存をしてください。
              </p>
              <p class="mb-0">
                ７．当社は、本サービスの提供において通常要求される程度の合理的な措置を当社が講じていたにもかかわらず、求人企業又は第三者の責めに帰すべき事由により生じた損害（ウィルスやハッキング、誤操作を原因とするサーバーダウン、システム障害、データの流出・損壊及び誤った情報の掲載などを含みます）につき、賠償する責任を一切負わないものとします。
              </p>
              <p class="mb-3">
                ８．当社は、本サービス若しくは本サイトからリンクされ、又は参照された第三者が運営するサイトやサービスに関して、いかなる保証もしないものとします。
              </p>

              <p class="mb-0">第１２条（当社の責任）</p>
              <p class="mb-3">
                当社は、本サービスの利用によって生じた損害につき、当社に故意または重過失がない限り責任を負わないものとします。当社が責任を負う場合でも、当社の責任は、利用者に直接かつ現実に生じた通常の損害に限られるものとします。
              </p>

              <p class="mb-0">第１３条（著作権等）</p>
              <p class="mb-3">
                本サービスに含まれるコンテンツ（文章、図表、画像、音声、プログラム等）の商標・特許・著作権・営業秘密・ノウハウその他の知的財産権は、当社または当社に利用を許諾した権利者に帰属しています。利用者は、本サービスの利用により、明示、黙示を問わず、複製、改変または翻案等の知的財産権を侵害する行為を行うことはできません。
              </p>

              <p class="mb-0">第１４条（リンク）</p>
              <p class="mb-3">
                利用者が当社の各サービスサイトにリンク設定を行う場合、リンク先の指定はありません。ただし、トップページ以下の階層構造は予告なく変更する場合があります。各サービスサイトへリンク設定を行うことによりトラブルが発生した場合、当社は、一切責任を負いません。
              </p>

              <p class="mb-0">第１５条（個人情報の取扱い）</p>
              <p class="mb-3">
                利用者の個人情報は、「プライバシーポリシー」に基づき取扱います。
              </p>

              <p class="mb-0">第１６条（統計データ）</p>
              <p class="mb-0">
                １．当社は、アンケート結果など本サービスに登録または提供された情報について、個人を特定する情報を除いたうえで当社の編集・発行する著作物等に二次使用することがあります（当社が運営する他のウェブサイトまたはアプリケーションに使用することを含みます）。
              </p>
              <p class="mb-0">
                ２．当社は、個人を特定できないように加工した利用状況や統計データを作成し、当該情報について何ら制約なく利用することができることとします。
              </p>
              <p class="mb-3">
                ３．前２項に基づき作成された二次著作物、統計データ等の著作権は、当社に帰属します。
              </p>

              <p class="mb-0">第１７条（反社会的勢力の排除）</p>
              <p class="mb-3">
                利用者は、現在かつ将来にわたり、反社会的勢力（暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団、その他これらに準ずる者をいう。）に該当しないこと、また暴力的な要求行為、法的な責任を超えた不当な要求行為、取引に関して脅迫的な言動をし、暴力を用いる行為、風説を流布し偽計を用いまたは威力を用いて信用を毀損しまたは業務を妨害する行為、その他これらに準ずる行為を行わないことを、将来にわたって表明するものとします。
              </p>

              <p class="mb-0">第１８条（サービスの中断、変更）</p>
              <p class="mb-0">
                １．当社は、本サービスの運営を良好に保つため、事前の通知なく、サービス内容の一時的な中断または変更をすることがあります。
              </p>
              <p class="mb-0">
                ２．当社は、２週間の予告期間をもって利用者へ告知のうえ、本サービスの長期的な中断または終了をすることができます。
              </p>
              <p class="mb-3">
                ３．前２項の措置により利用者に不利益が生じた場合でも、当社は、当社に故意または重過失があるときを除き、利用者に対して責任を負いません。
              </p>

              <p class="mb-0">第１９条（規約の変更）</p>
              <p class="mb-0">
                １．当社は、法令等の制定・改廃があった場合または新サービスの追加等、当社が必要と判断した場合に、利用者の承諾を得ることなく、本規約等を変更することができるものとします。当社が本規約等を変更した場合には、利用者は、変更後の本規約等に従い本サービスを利用するものとします。
              </p>
              <p class="mb-0">
                ２．当社が本規約等の変更を行う場合には、変更の１ヵ月以上前に変更後の規約の内容および効力発生日を当社のウェブサイト上に表示または当社の定める方法により利用者に通知することで周知するものとし、その期間経過をもって、効力が発生するものとします。
              </p>
              <p class="mb-3">
                ３．前項にかかわらず、当社は、誤記訂正や形式的修正など変更が軽微な場合および本サービス利用中の利用者に効力を及ぼさない場合は、規約変更について通知しないものとします。
              </p>

              <p class="mb-0">第２０条（事業譲渡）</p>
              <p class="mb-0">
                １．当社は、本サービスにかかる事業を他社に譲渡した場合、当該事業譲渡に伴い、本規約等に基づく権利・義務および利用者の提供情報、登録情報その他の情報等を当該事業譲渡の譲受人に譲渡することができるものとします。
              </p>
              <p class="mb-3">
                ２．前項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他運営主体が変更されるあらゆる場合を含むものとします。
              </p>

              <p class="mb-0">第２１条（定めのない事項）</p>
              <p class="mb-3">
                本規約および諸規程等に定めのない事項については、会員は当社の指示に従うものとします。
              </p>

              <p class="mb-0">第２２条（分離可能性）</p>
              <p class="mb-3">
                本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
              </p>

              <p class="mb-0">第２３条（準拠法及び管轄裁判所）</p>
              <p class="mb-0">
                １．本規約その他当社が定める規約及び当事者間の契約（以下「本規約等」と言います。）は、日本法を準拠とし、解釈されるものとします。
              </p>
              <p class="mb-3">
                ２．本規約等および本サービスに関する紛争については、東京簡易裁判所または東京地方裁判所を第一審の専属的合意管轄裁判所とします。
              </p>

              <p class="mb-0">制定日　２０２１年１０月１日</p>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TermsAndConditions',
  components: {},
  metaInfo: {
    title: 'コトナル'
  },
  data() {
    return {
      initialLoad: true,
      loading: false
    }
  },
  created() {},
  computed: {},
  methods: {}
}
</script>

<style lang="scss" src="./__style.scss" scope></style>
